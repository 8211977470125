import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Every year americans buy a bunch of christmas trees. Use the dataset to compare
real and fake sales with two bar charts.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactviz.holiday/datasets/statistic_id209249_christmas-trees-sold-in-the-united-states-2004-2017.xlsx"
      }}>{`Dataset`}</a></p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/Blyq4m0CvxY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/p9p89w86wx?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`Christmas trees sold in USA is an example of a simple barchart built with React
and D3. More React than D3 really. 😇`}</p>
    <p>{`We used the simplified full integration approach because the data never
changes. Neither do any of the other props like `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{`. Means we
don't have to worry about updating D3's internal state. Plopping D3 stuff into
class field properties works great.`}</p>
    <p>{`We converted our dataset from xlsx into a tab separated values file. Easy copy
paste job with this tiny dataset. That goes in `}<inlineCode parentName="p">{`/public/data.tsv`}</inlineCode>{`.`}</p>
    <p>{`To load the dataset we use `}<inlineCode parentName="p">{`d3.tsv`}</inlineCode>{` inside `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  componentDidMount() {
    d3.tsv("/data.tsv", ({ year, real_trees, fake_trees }) => ({
      year: Number(year),
      real_trees: Number(real_trees),
      fake_trees: Number(fake_trees)
    })).then(data => this.setState({ data }));
  }
`}</code></pre>
    <p>{`When the `}<inlineCode parentName="p">{`<App>`}</inlineCode>{` component first loads, it makes a `}<inlineCode parentName="p">{`fetch()`}</inlineCode>{` request for our
data file. D3 parses the file as a list of tab separated values and passes each
line through our data parsing function. That turns it into a nicely formatted
object with real numbers.`}</p>
    <p><inlineCode parentName="p">{`.then`}</inlineCode>{` we update component state with our data.`}</p>
    <p>{`Inside the render method we use a conditional. When data is present, we render
a `}<inlineCode parentName="p">{`<Barchart>`}</inlineCode>{` component with a title. When there's no data, we render nothing.`}</p>
    <p>{`No need for a loading screen with a dataset this small. Loads and parses super
fast. 👌`}</p>
    <h3 {...{
      "id": "render-the-emoji-barchart"
    }}>{`Render the emoji barchart`}</h3>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/raw/master/src/content/christmas-trees/christmastrees.gif",
        "alt": "Switchable emoji christmas tree barchart"
      }}></img></p>
    <p>{`We created a `}<inlineCode parentName="p">{`<Barchart>`}</inlineCode>{` component that takes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`data`}</inlineCode>{`, our entire dataset`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`value`}</inlineCode>{`, the key name we're displaying`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`y`}</inlineCode>{`, the vertical position`}</li>
    </ul>
    <p>{`Final version doesn't need that vertical positioning param, but it's nice to
have. You never know.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Barchart`}</inlineCode>{` uses a horizontal `}<inlineCode parentName="p">{`scaleBand`}</inlineCode>{` to handle each column's
positioning. Scale bands are a type of ordinal scale. They automatically handle
spacing, padding, and making sure our columns neatly fit into a given width.`}</p>
    <p>{`There's no height axis because we want each christmas tree emoji 🎄 to
represent a million real life trees.`}</p>
    <p>{`We loop over the data and render a `}<inlineCode parentName="p">{`TreeBar`}</inlineCode>{` and a `}<inlineCode parentName="p">{`text`}</inlineCode>{` label for each entry.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<g transform={\`translate(0, \${y})\`}>
  {data.map(d => (
    <React.Fragment>
      <TreeBar x={this.xScale(d.year)} y={480} count={d[this.props.value]} />
      <text
        x={this.xScale(d.year)}
        y={500}
        style={{ strike: 'black', fontSize: '12px' }}
        textAnchor="center"
      >
        {d.year}
      </text>
    </React.Fragment>
  ))}
</g>
`}</code></pre>
    <p>{`A grouping element holds everything in place and creates a common coordinate
system to work off of. It's like a div. We position it with a
`}<inlineCode parentName="p">{`transform=translate`}</inlineCode>{` and elements inside position relatively to the group.`}</p>
    <p>{`For each iteration of our `}<inlineCode parentName="p">{`data`}</inlineCode>{`, we render a `}<inlineCode parentName="p">{`<TreeBar>`}</inlineCode>{` and a `}<inlineCode parentName="p">{`<text>`}</inlineCode>{`. Text
goes at the bottom and displays the year. That's our label.`}</p>
    <p><inlineCode parentName="p">{`<TreeBar>`}</inlineCode>{` gets a horizontal position through our `}<inlineCode parentName="p">{`xScale`}</inlineCode>{`, a vertical
position which is static, and a `}<inlineCode parentName="p">{`count`}</inlineCode>{` of how many trees 🎄 to show. We use
`}<inlineCode parentName="p">{`this.props.value`}</inlineCode>{` to dynamically fetch the correct part of each data object.`}</p>
    <h3 {...{
      "id": "a-treebar-of-emojis-"
    }}>{`A `}<inlineCode parentName="h3">{`<TreeBar>`}</inlineCode>{` of emojis 🎄`}</h3>
    <p>{`Now here's a silly little fun part: Instead of an SVG rectangle, we build each
bar from a bunch of emoji text elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const TreeBar = ({ x, y, count }) => (
  <g transform={\`translate(\${x}, \${y})\`}>
    {d3.range(count).map(i => (
      <text x={0} y={-i * 12} style={{ fontSize: '20px' }}>
        🎄
      </text>
    ))}
    <text
      x={0}
      y={-(count + 1) * 12 - 5}
      style={{ fontSize: '9px' }}
      textAnchor="center"
    >
      {count}
    </text>
  </g>
);
`}</code></pre>
    <p>{`Once more we start with a grouping element. That holds everything together.`}</p>
    <p>{`Then we create a fake array with `}<inlineCode parentName="p">{`d3.range`}</inlineCode>{` and iterate. For each index in the
array, we return a text element positioned at a `}<inlineCode parentName="p">{`12px`}</inlineCode>{` offset from the previous
element, a `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` of `}<inlineCode parentName="p">{`20px`}</inlineCode>{`, and a christmas tree emoji for content.`}</p>
    <p>{`We found the values through experiment. Keeping emojis spaced to their full
height created bars that were too hard to read. An overlap works great. Keeps
the bars readable and the emojis recognizable.`}</p>
    <p>{`That final little text shows how many million trees we drew in each bar. Makes
our chart easier to read than counting tres :)`}</p>
    <h3 {...{
      "id": "what-you-learned-🧐"
    }}>{`What you learned 🧐`}</h3>
    <p>{`Today you learned:`}</p>
    <ul>
      <li parentName="ul">{`D3 band scales`}</li>
      <li parentName="ul">{`D3 range for creating iteration arrays`}</li>
      <li parentName="ul">{`using class field values for D3 objects`}</li>
      <li parentName="ul">{`a simple full integration approach`}</li>
    </ul>
    <p>{`React for rendering. D3 to help calculate props.`}</p>
    <p>{`✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      